<template>
  <b-card
    no-body
    class="p-2"
  >
    <h3 class="mb-2">
      {{ $t('AppTransportationLines.Edit.editTransportLine') }}
      <!-- {{ $t('DataAccess.ConditionFilters.Edit.modifyConditionFilter') }} -->
    </h3>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!-- Customs field -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Customs"
              rules="required"
            >
              <b-form-group
                :label="$t('AppTransportationLines.columns.typeCustom')"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  v-model="TransportationLine.aduanaTipoId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  label="texto"
                  :options="CustomType"
                  :reduce="option => option.id"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Name Field -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <b-form-group
                :label="$t('AppTransportationLines.columns.name')"
                :state="errors.length > 0 ? false:null"
              >
                <b-form-input
                  v-model="TransportationLine.nombre"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--Alias field--->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Alias"
              rules="required"
            >
              <b-form-group
                :label="$t('AppTransportationLines.columns.nickname')"
                :state="errors.length > 0 ? false:null"
              >
                <b-form-input
                  v-model="TransportationLine.alias"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--Description field--->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Description"
              rules="required"
            >
              <b-form-group
                :label="$t('AppTransportationLines.columns.description')"
                :state="errors.length > 0 ? false:null"
              >
                <b-form-input
                  v-model="TransportationLine.descripcion"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          {{ $t('Lists.saveChanges') }}
        </b-button>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  // BFormTextarea,

  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import generalDataService from '@/services/generalData.service'
import { ref } from '@vue/composition-api'
import router from '@/router'
import vSelect from 'vue-select'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormInvalidFeedback,
    BFormGroup,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,

    vSelect,
  },
  setup() {
    /* SERVICES */
    const {
      fetchTransportationLine,
      updateTransportationLine,
      fetchCustomTypesOptions,
    } = generalDataService()

    // Data
    const TransportationLine = ref({})
    fetchTransportationLine(router.currentRoute.params, data => {
      TransportationLine.value = data
    })
    const CustomType = ref([])
    fetchCustomTypesOptions(data => {
      CustomType.value = data
    })
    /* Events */
    const onSubmit = () => {
      updateTransportationLine(TransportationLine.value)
        .then(() => {
          router.push({ name: 'apps-transportation-lines-list' })
        })
    }
    /* Validations */
    const TransportationLineData = ref(JSON.parse(JSON.stringify(TransportationLine.value)))
    const resetclienteData = () => {
      TransportationLineData.value = JSON.parse(JSON.stringify(TransportationLine))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetclienteData)
    return {
      // Data
      TransportationLine,
      CustomType,
      /* Events */
      onSubmit,
      // Validations
      required,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style>

</style>
