var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"p-2",attrs:{"no-body":""}},[_c('h3',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('AppTransportationLines.Edit.editTransportLine'))+" ")]),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Customs","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AppTransportationLines.columns.typeCustom'),"state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"clearable":false,"label":"texto","options":_vm.CustomType,"reduce":function (option) { return option.id; }},model:{value:(_vm.TransportationLine.aduanaTipoId),callback:function ($$v) {_vm.$set(_vm.TransportationLine, "aduanaTipoId", $$v)},expression:"TransportationLine.aduanaTipoId"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AppTransportationLines.columns.name'),"state":errors.length > 0 ? false:null}},[_c('b-form-input',{model:{value:(_vm.TransportationLine.nombre),callback:function ($$v) {_vm.$set(_vm.TransportationLine, "nombre", $$v)},expression:"TransportationLine.nombre"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Alias","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AppTransportationLines.columns.nickname'),"state":errors.length > 0 ? false:null}},[_c('b-form-input',{model:{value:(_vm.TransportationLine.alias),callback:function ($$v) {_vm.$set(_vm.TransportationLine, "alias", $$v)},expression:"TransportationLine.alias"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AppTransportationLines.columns.description'),"state":errors.length > 0 ? false:null}},[_c('b-form-input',{model:{value:(_vm.TransportationLine.descripcion),callback:function ($$v) {_vm.$set(_vm.TransportationLine, "descripcion", $$v)},expression:"TransportationLine.descripcion"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs',"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Lists.saveChanges'))+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }